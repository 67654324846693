.wrapper{
  &::-webkit-scrollbar{
    width: 8px; /* Sesuaikan ukuran scrollbar */
    height: 8px; /* Sesuaikan ukuran horizontal scrollbar */ 
  }

  &::-webkit-scrollbar-track {
    background-color: initial;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #6F737E;
  }
  
  &::-webkit-scrollbar-thumb:active {
    background-color: #6F737E;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #6F737E;
  }

  tbody{
    tr{
      &:nth-child(odd){
        background-color: rgba(black, .10);
      }
    }
  }
}